import { Feather } from '@expo/vector-icons';
import generalConfig from '_/config/general';
import { colors, fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import { View, StyleSheet, TouchableOpacity, TextStyle, StyleProp } from 'react-native';

import Text from '../Text/index';

interface TitleProps {
  title?: string | null;
  hasCloseButton?: boolean;
  onPress?: () => void;
  style?: StyleProp<TextStyle>;
  numberOfLines?: number;
}
const fontType = generalConfig.fonts.fontType;

export default function Title({
  title,
  hasCloseButton,
  onPress,
  style,
  numberOfLines = 1,
}: TitleProps) {
  return (
    <View style={styles.componentHeader}>
      <Text
        fontSize={fontSizes.lg2}
        color={colors.black}
        fontType={fontType}
        style={[styles.title, style]}
        numberOfLines={numberOfLines}
      >
        {title}
      </Text>
      {hasCloseButton && (
        <TouchableOpacity hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }} onPress={onPress}>
          <Feather name="x" size={24} />
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  componentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md,
    marginTop: spacing.md,
    marginBottom: spacing.xxs,
  },
  title: {
    maxWidth: '100%',
  },
});
