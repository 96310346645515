import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useLocationContext } from '_/hooks/LocationContext';
import { useNotificationContext } from '_/hooks/NotificationsContext';
import { AppRoute } from '_/navigation/types';
import { logoSize } from '_/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  ViewStyle,
  StyleProp,
  Platform,
} from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';

import ArrowBackHeader from '../ArrowBackHeader';
import Avatar from '../Avatar/index';
import Text from '../Text';
import WebWrapper from '../WebWrapper';

interface HeaderProps {
  screenName?: string;
  organizationLogo?: string | null;
  textColor?: string;
  backButtonIsVisible?: boolean;
  isHomeScreen?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
}

export default function Header({
  screenName,
  organizationLogo,
  isHomeScreen = false,
  containerStyle,
  textColor,
}: HeaderProps) {
  const navigation = useNavigation<any>();
  const { user, isWebView } = useAuth();
  const { notReadNotifications } = useNotificationContext();
  const { locations } = useLocationContext();
  const [size, setSize] = useState({ width: 200, height: 30 });
  const HandleReportButton = useCallback(() => {
    const isReportEnabled = locations.data?.filter((location) => location.isReportEnabled === true);

    if (isReportEnabled?.length > 0) {
      return (
        <TouchableOpacity
          hitSlop={{ top: 8, bottom: 8 }}
          style={styles.option}
          onPress={() => navigation.navigate(AppRoute.REPORT_PROBLEM)}
        >
          <Feather name="alert-triangle" size={24} />
        </TouchableOpacity>
      );
    } else {
      return null;
    }
  }, [locations?.data, navigation]);
  useEffect(() => {
    if (organizationLogo) {
      Image.getSize(organizationLogo, (width, height) => setSize({ width, height }));
    }
  }, [organizationLogo]);
  const dimensions = useMemo(() => {
    return logoSize(size.width, size.height);
  }, [size]);

  const HandleOrganizationLogo = useCallback(() => {
    if (isHomeScreen) {
      return (
        <Image
          resizeMode="contain"
          style={{ width: dimensions.width, height: dimensions.height }}
          source={organizationLogo ? { uri: organizationLogo } : images.headerLogo}
        />
      );
    } else {
      return <ArrowBackHeader textColor={textColor}>{screenName}</ArrowBackHeader>;
    }
  }, [isHomeScreen, organizationLogo, screenName, textColor, dimensions]);

  return (
    <WebWrapper>
      <View style={[styles.wrapper, containerStyle, isWebView && { marginTop: 0 }]}>
        <HandleOrganizationLogo />
        {isHomeScreen && !isWebView && (
          <View style={styles.optionsGroup}>
            {Platform.OS !== 'web' && <HandleReportButton />}

            {Platform.OS !== 'web' && (
              <TouchableOpacity
                hitSlop={{ top: 8, bottom: 8 }}
                style={styles.option}
                onPress={() => navigation.navigate(AppRoute.NOTIFICATIONS)}
              >
                <Feather name="bell" size={24} />
                {notReadNotifications > 0 && (
                  <View style={styles.notificationCircle}>
                    <Text color={colors.white} fontSize={fontSizes.sm}>
                      {notReadNotifications}
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
            )}

            <TouchableOpacity
              hitSlop={{ top: 8, bottom: 8 }}
              style={styles.option}
              onPress={() => navigation.navigate(AppRoute.PROFILE_STACK_SCREEN)}
            >
              <Avatar size={32} hasNoAvatar={!user.pictureUrl} source={user.pictureUrl} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    </WebWrapper>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.md,
    justifyContent: 'space-between',
    marginTop: isIphoneX() ? 50 : 40,
    paddingVertical: spacing.sm,
    backgroundColor: 'transparent',
  },
  optionsGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  option: {
    paddingHorizontal: 8,
  },
  notificationCircle: {
    top: -8,
    right: 4,
    position: 'absolute',
    height: 20,
    width: 20,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.errorRed,
  },
});
