import { Feather } from '@expo/vector-icons';
import { useRoute, useNavigation } from '@react-navigation/native';
import { Button, Title, SpaceTypeHeader, WebWrapper } from '_/components';
import { DATE_FORMAT, DAY_MONTH_YEART_FORMAT } from '_/config/date';
import { colors } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useDateContext } from '_/hooks/DateContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { AppRoute } from '_/navigation/types';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { DisabledSpaceDateType, SpacesModel } from '_/services/models/spaces.model';
import { parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import { Calendar, DateData, LocaleConfig } from 'react-native-calendars';
import { showMessage } from 'react-native-flash-message';

import HandleTimePicker from './handleTimePicker';
import styles, {
  calendarTheme,
  selectedCustomMark,
  todayCustomMark,
  customDisabledDates,
} from './styles';

interface ParamsProps {
  locationName?: string;
  space: SpacesModel;
  availableSlotsCount: string;
}

export default function DatePicker() {
  const navigation = useNavigation<any>();
  const {
    setSelectedDay,
    selectedDay,
    today,
    isIntervalInvalid,
    hasStartTimeBeforeCurrent,
    maxDate,
    timePickerVisible,
    checkOpenCloseHourMin,
  } = useDateContext();
  const { setSlotSearchValue } = useSlotContext();
  const route = useRoute();
  const { availableSlotsCount, locationName, space } = route.params as ParamsProps;
  const [markedDates, setMarkedDates] = useState({});
  const { disabledDateData, currentSpace } = useSpaceContext();
  const invalidDate = (isIntervalInvalid || hasStartTimeBeforeCurrent) && timePickerVisible;
  const disabledButton = invalidDate || !selectedDay;
  const { t } = useTranslation();

  useEffect(() => {
    if (disabledDateData.length > 0) {
      const disabledDates = disabledDateData.map((item: DisabledSpaceDateType) =>
        parseISO(item.date)
      );
      const disabled = disabledDates.map((date) => {
        const aux = {
          [formatDate(today, DATE_FORMAT)]: { selected: true, customStyles: todayCustomMark },
          [selectedDay]: { selected: true, customStyles: selectedCustomMark(currentSpace.type) },
          [formatDate(date, DATE_FORMAT)]: {
            selected: true,
            customStyles: customDisabledDates,
          },
        };
        return aux;
      });
      const objDates = Object.values(Object.assign({}, disabled));
      const marks = objDates.reduce((a, b) => {
        return { ...a, ...b };
      }, {});
      setMarkedDates(marks);
    } else {
      const marks = {
        [formatDate(today, DATE_FORMAT)]: { selected: true, customStyles: todayCustomMark },
        [selectedDay]: { selected: true, customStyles: selectedCustomMark(currentSpace.type) },
      };
      setMarkedDates(marks);
    }
  }, [disabledDateData, selectedDay, space, today]);

  const goToSlots = useCallback(() => {
    if (
      (currentSpace.type === SpaceType.WORK && checkOpenCloseHourMin()) ||
      (timePickerVisible && checkOpenCloseHourMin())
    ) {
      showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
          openAt: currentSpace?.openAtHourMin ?? '00:00',
          closeAt: currentSpace?.closeAtHourMin ?? '23:59',
        }),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    if (currentSpace.type === SpaceType.WORK) {
      setSlotSearchValue(undefined);
    }
    navigation.navigate(AppRoute.WORK, {
      spaceType: currentSpace.type,
      locationName,
      availableSlotsCount,
    });
  }, [
    t,
    availableSlotsCount,
    locationName,
    navigation,
    setSlotSearchValue,
    currentSpace.type,
    checkOpenCloseHourMin,
    timePickerVisible,
    currentSpace?.openAtHourMin,
    currentSpace?.closeAtHourMin,
  ]);

  LocaleConfig.locales['pt-BR'] = {
    monthNames: [
      t('months.january'),
      t('months.february'),
      t('months.march'),
      t('months.april'),
      t('months.may'),
      t('months.june'),
      t('months.july'),
      t('months.august'),
      t('months.september'),
      t('months.october'),
      t('months.november'),
      t('months.december'),
    ],
    monthNamesShort: [
      t('months.jan'),
      t('months.feb'),
      t('months.mar'),
      t('months.apr'),
      t('months.mayShort'),
      t('months.jun'),
      t('months.jul'),
      t('months.aug'),
      t('months.sep'),
      t('months.oct'),
      t('months.nov'),
      t('months.dec'),
    ],
    dayNames: [
      t('weekDays.sunday'),
      t('weekDays.monday'),
      t('weekDays.tuesday'),
      t('weekDays.wednesday'),
      t('weekDays.thursday'),
      t('weekDays.friday'),
      t('weekDays.saturday'),
    ],
    dayNamesShort: [
      t('weekDays.SU'),
      t('weekDays.M'),
      t('weekDays.T'),
      t('weekDays.W'),
      t('weekDays.TH'),
      t('weekDays.F'),
      t('weekDays.S'),
    ],
  };
  LocaleConfig.defaultLocale = 'pt-BR';

  const handleDayPress = useCallback(
    (day: DateData) => {
      const isDisabled = disabledDateData.filter(
        (item: DisabledSpaceDateType) => item.date.slice(0, 10) === day.dateString
      );

      if (isDisabled.length === 0) {
        setSelectedDay(day.dateString);
      } else {
        showMessage({
          message: t('closedSpace.closedSpaceMessage')
            .concat(' ')
            .concat(formatDate(parseISO(isDisabled[0].date), DAY_MONTH_YEART_FORMAT)),
          ...(isDisabled[0].reason && {
            description: t('closedSpace.reason').concat(': ').concat(isDisabled[0].reason),
          }),
          backgroundColor: colors.errorRed,
          duration: 5000,
        });
      }
    },
    [disabledDateData, setSelectedDay, t]
  );

  return (
    <WebWrapper>
      <View style={styles.modal}>
        <SpaceTypeHeader
          spaceType={currentSpace.type}
          availableSlotsCount={availableSlotsCount}
          locationName={locationName}
          backButtonIsVisible={false}
        />
        <ScrollView
          style={styles.scrollView}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.scrollViewContainer}
        >
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'position' : undefined}
          >
            <Title title={t('datePickerScreen.whenDoYouWantToReserve')} />
            <Calendar
              theme={calendarTheme(currentSpace.type)}
              minDate={today?.toISOString()}
              maxDate={maxDate?.toISOString()}
              onDayPress={handleDayPress}
              markingType="custom"
              markedDates={markedDates}
              renderArrow={(direction) => (
                <Feather name={`chevron-${direction}` as any} size={28} color={colors.darkGrey} />
              )}
            />
            <HandleTimePicker space={currentSpace} />
            <View style={styles.wrapper}>
              <View style={{ opacity: disabledButton ? 0.2 : 1 }}>
                <Button
                  backgroundColor={getSpaceTypeColor(currentSpace.type)}
                  textColor={colors.white}
                  disabled={disabledButton}
                  onPress={goToSlots}
                >
                  {t('next')}
                </Button>
              </View>
              <Button onPress={navigation.goBack}>{t('cancel')}</Button>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    </WebWrapper>
  );
}
