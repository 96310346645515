import AsyncStorage from '@react-native-async-storage/async-storage';
import authConfig from '_/config/auth';
import generalConfig from '_/config/general';
import { colors } from '_/constants/theme';
import { usersApi } from '_/services/api';
import logger from '_/services/logger';
import { MembersModel } from '_/services/models/members.model';
import { UsersModel } from '_/services/models/users.model';
import { getDeviceId } from '_/services/notifications';
import { TokenResponse } from 'expo-auth-session';
import * as Google from 'expo-google-app-auth';
import * as WebBrowser from 'expo-web-browser';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import * as Sentry from 'sentry-expo';

import { AuthContextData, AuthState } from '../services/models/auth.model';
import { useLanguage } from './LanguageContext';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type AuthType = {
  children: React.ReactNode;
};

WebBrowser.maybeCompleteAuthSession();

export const AuthProvider: React.FC<AuthType> = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [isWebView, setWebView] = useState(false);
  const { changeLanguage } = useLanguage();
  const { t } = useTranslation();

  const handleRedirect = async () => {
    const canRedirect = await Linking.canOpenURL(window.location.origin);
    if (canRedirect) {
      window.location.replace(window.location.origin);
    }
  };

  const signInWithToken = async (
    refreshToken: string,
    webView?: boolean,
    currentOrganizationId?: string
  ) => {
    try {
      const decodedJwt: JwtPayload = jwt_decode(refreshToken);
      const userId = decodedJwt.sub;

      if (!userId) {
        throw new Error();
      }
      let accessToken = '';
      if (webView && currentOrganizationId) {
        accessToken = refreshToken;
        usersApi.setCurrentOrganization(userId, currentOrganizationId, accessToken);
      } else {
        const data = await usersApi.refreshToken(refreshToken, userId);
        accessToken = data.accessToken;
      }

      if (userId) {
        const user = await usersApi.getItem(userId, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            includeMember: 'true',
          },
        });

        if (accessToken && user) {
          setData({
            token: accessToken,
            user,
            member: user.member,
          });
        }
        if (user.member) {
          await AsyncStorage.setItem(authConfig.memberKey, JSON.stringify(user.member));
        }
        if (user.lang) {
          changeLanguage(user.lang);
        }
        await AsyncStorage.multiSet([
          [authConfig.tokenKey, accessToken],
          [authConfig.userKey, JSON.stringify(user)],
          [authConfig.userIdKey, user.id],
          [authConfig.webview, webView ? String(Boolean(webView)) : ''],
        ]);

        usersApi.setToken(accessToken, refreshToken, userId);
        if (!webView) await handleRedirect();
      }
    } catch (error) {
      if (!webView) await handleRedirect();
      logger(error);
    }
    setAuthLoading(false);
  };

  const webSignInGoogle = async (accessToken: TokenResponse) => {
    setAuthLoading(true);
    const result = await usersApi.signInWithGoogle({ accessToken: accessToken.accessToken });
    const { accessToken: token, user, member, refreshToken } = result;
    if (member) {
      await AsyncStorage.setItem(authConfig.memberKey, JSON.stringify(member));
    }
    if (user.lang) {
      changeLanguage(user.lang);
    }

    setData({ token, user, member, refreshToken });
    const timeStamp = String(new Date().getTime());
    await AsyncStorage.multiSet([
      [authConfig.tokenKey, token],
      [authConfig.userKey, JSON.stringify(user)],
      [authConfig.userIdKey, user.id],
      [authConfig.refreshTokenKey, refreshToken],
      [authConfig.loadUserTimestamp, timeStamp],
    ]);
    setAuthLoading(false);
  };
  const signInMicrosoft = async (response: TokenResponse) => {
    setAuthLoading(true);
    const result = await usersApi.signInWithMicrosoft(response.accessToken);
    const { accessToken: token, user, member, refreshToken } = result;
    if (member) {
      await AsyncStorage.setItem(authConfig.memberKey, JSON.stringify(member));
    }
    if (user.lang) {
      changeLanguage(user.lang);
    }

    setData({ token, user, member, refreshToken });
    const timeStamp = String(new Date().getTime());
    await AsyncStorage.multiSet([
      [authConfig.tokenKey, token],
      [authConfig.userKey, JSON.stringify(user)],
      [authConfig.userIdKey, user.id],
      [authConfig.refreshTokenKey, refreshToken],
      [authConfig.loadUserTimestamp, timeStamp],
    ]);
    setAuthLoading(false);
  };

  const signIn = async ({ email, password }: { email: string; password: string }) => {
    try {
      const result = await usersApi.signIn({ email, password, strategy: 'local' });
      const { accessToken, user, member, refreshToken } = result;
      if (member) {
        await AsyncStorage.setItem(authConfig.memberKey, JSON.stringify(member));
      }
      if (user.lang) {
        changeLanguage(user.lang);
      }
      setData({ token: accessToken, user, member, refreshToken });
      const timeStamp = String(new Date().getTime());
      await AsyncStorage.multiSet([
        [authConfig.tokenKey, accessToken],
        [authConfig.userKey, JSON.stringify(user)],
        [authConfig.userIdKey, user.id],
        [authConfig.refreshTokenKey, refreshToken],
        [authConfig.loadUserTimestamp, timeStamp],
      ]);

      return result;
    } catch (error) {
      logger(error);
      throw error;
    } finally {
      setAuthLoading(false);
    }
  };

  const signInGoogle = async () => {
    try {
      const response = await Google.logInAsync(generalConfig.googleLoginConfig);
      if (response.type === 'success') {
        const { accessToken: googleToken } = response;
        const result = await usersApi.signInWithGoogle({ accessToken: googleToken });

        const { accessToken: token, user, member, refreshToken } = result;

        if (member) {
          await AsyncStorage.setItem(authConfig.memberKey, JSON.stringify(member));
        }

        if (user.lang) {
          changeLanguage(user.lang);
        }
        setData({ token, user, member, refreshToken });
        const timeStamp = String(new Date().getTime());
        await AsyncStorage.multiSet([
          [authConfig.tokenKey, token],
          [authConfig.userKey, JSON.stringify(user)],
          [authConfig.userIdKey, user.id],
          [authConfig.refreshTokenKey, refreshToken],
          [authConfig.loadUserTimestamp, timeStamp],
        ]);
      }

      if (response.type === 'cancel') {
        setAuthLoading(false);
      }
    } catch {
      showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.googleSignInError'),
        backgroundColor: colors.errorRed,
      });
      setAuthLoading(false);
    }
  };

  const signOut = async () => {
    const deviceId = await getDeviceId();
    try {
      await usersApi.deleteUserToken(deviceId);
    } catch (error) {
      logger(error);
    }

    try {
      await AsyncStorage.multiRemove([
        authConfig.tokenKey,
        authConfig.userKey,
        authConfig?.memberKey,
        authConfig.refreshTokenKey,
        authConfig.userIdKey,
        authConfig.tokenExpirationKey,
        authConfig.loadUserTimestamp,
      ]);
      setData({} as AuthState);
      usersApi.removeToken();
    } catch (error) {
      logger(error);
    }
  };

  const updateUser = async (newData: Partial<UsersModel>) => {
    try {
      const result = await usersApi.update(data.user.id, newData);
      await AsyncStorage.setItem(authConfig.userKey, JSON.stringify(result));

      setData((prevState) => ({
        ...prevState,
        user: result,
      }));
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const updateEmailNotification = async (emailNotification: boolean) => {
    try {
      const result = await usersApi.update(data.user.id, { emailNotification });
      await AsyncStorage.setItem(authConfig.userKey, JSON.stringify(result));

      setData((prevState) => ({
        ...prevState,
        user: result,
      }));
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const updateMember = async (member: MembersModel) => {
    try {
      await AsyncStorage.setItem(authConfig.memberKey, JSON.stringify(member));
      setData((prevState) => ({
        ...prevState,
        member,
      }));
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const updateUserCurrentOrganization = async ({
    id,
    currentOrganizationId,
  }: {
    id: string;
    currentOrganizationId: string;
  }) => {
    try {
      const result = await usersApi.update(id, {
        currentOrganizationId,
      });

      await AsyncStorage.setItem(authConfig.userKey, JSON.stringify(result));
      setData((prevState) => ({
        ...prevState,
        user: result,
      }));
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const uploadAvatar = async (formData: FormData) => {
    try {
      const data = await usersApi.uploadAvatar(formData);

      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
  async function loadStoragedData(): Promise<void> {
    const [token, user, member, refreshToken, userId] = await AsyncStorage.multiGet([
      authConfig.tokenKey,
      authConfig.userKey,
      authConfig.memberKey,
      authConfig.refreshTokenKey,
      authConfig.userIdKey,
    ]);

    if (token[1] && user[1]) {
      setData({
        token: token[1],
        user: JSON.parse(user[1]),
        member: JSON.parse(member[1] || ''),
      });

      usersApi.setToken(token[1], refreshToken[1] as string, userId[1] as string);
    }
  }

  useEffect(() => {
    async function loadUser() {
      const [token, refreshToken, userId, timeStamp, webView] = await AsyncStorage.multiGet([
        authConfig.tokenKey,
        authConfig.refreshTokenKey,
        authConfig.userIdKey,
        authConfig.loadUserTimestamp,
        authConfig.webview,
      ]);
      setWebView(Boolean(webView[1]));
      const isLargeThanFiveSeconds = new Date().getTime() - Number(timeStamp[1]) > 5000;
      if (isLargeThanFiveSeconds) {
        if (token[1] && refreshToken[1] && userId[1]) {
          const { member, ...user } = await usersApi.getItem(userId[1], {
            headers: {
              Authorization: `Bearer ${token[1]}`,
            },
            params: {
              includeMember: 'true',
            },
          });

          setData({
            token: token[1],
            refreshToken: refreshToken[1],
            userId: userId[1],
            user,
            member,
          });
          const timeStamp = String(new Date().getTime());
          await AsyncStorage.multiSet([
            [authConfig.userKey, JSON.stringify(user)],
            [authConfig.memberKey, JSON.stringify(member || '')],
            [authConfig.loadUserTimestamp, timeStamp],
          ]);

          changeLanguage(user.lang);
        }
      } else {
        loadStoragedData();
      }
      setAuthLoading(false);
    }

    if (Platform.OS === 'web') {
      const getParamsFromURL = async () => {
        const url = await Linking.getInitialURL();
        if (url) {
          const urlParams = new URL(url).searchParams;
          const tokenParam = urlParams.get('token');
          const webViewParam = urlParams.get('webView');
          const currentOrganizationId = urlParams.get('currentOrganizationId');
          if (tokenParam && webViewParam && currentOrganizationId) {
            setAuthLoading(true);
            setWebView(Boolean(webViewParam));
            await signInWithToken(tokenParam, Boolean(webViewParam), currentOrganizationId);
            return;
          }
          if (tokenParam) {
            const refreshToken = tokenParam;
            setAuthLoading(true);
            await signInWithToken(refreshToken);
          }
        }
      };
      getParamsFromURL();
    }
    setAuthLoading(true);
    loadUser();
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      Sentry.Browser.setUser({ email: data.user?.email });
    } else {
      Sentry.Native.setUser({ email: data.user?.email });
    }
    // TODO: Set analytics user id
    // setUserId(data.user?.id ?? null);
  }, [data.user]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        user: data.user,
        member: data.member,
        signOut,
        updateUser,
        uploadAvatar,
        updateUserCurrentOrganization,
        updateMember,
        updateEmailNotification,
        signInGoogle,
        webSignInGoogle,
        signInMicrosoft,
        authLoading,
        setAuthLoading,
        isWebView,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
