import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import Button from '_/components/Button';
import Text from '_/components/Text';
import Title from '_/components/Title';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { networkErrorMessage } from '_/helpers/networkError';
import { useAuth } from '_/hooks/AuthContext';
import { useEventContext } from '_/hooks/EventContext';
import useFirebaseAnalytics from '_/hooks/useFirebaseAnalytics';
import { AppRoute } from '_/navigation/types';
import { eventsApi } from '_/services/api';
import { EventsResponse } from '_/services/models/events.model';
import handleShowMessageToast from '_/utils/handleShowMessageToast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';

import MapModal from './MapModal';

interface CheckModalInterface {
  id: string;
  event: EventsResponse;
  visible: boolean;
  checkinAt?: string;
  mapModalVisible: boolean;
  setMapModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onBackdropPress: () => void;
  onBackButtonPress: () => void;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CheckModal({
  id,
  event,
  visible,
  checkinAt,
  onBackdropPress,
  onBackButtonPress,
  setModalVisible,
  mapModalVisible,
  setMapModalVisible,
}: CheckModalInterface) {
  const navigation = useNavigation<any>();
  const { getEvents } = useEventContext();
  const [loading, setLoading] = useState(false);
  const { analyticsLogEvent } = useFirebaseAnalytics();
  const { t } = useTranslation();
  const { isWebView } = useAuth();

  const handleCheckout = async () => {
    setLoading(true);
    try {
      await eventsApi.checkout({ id });
      await getEvents();
      handleShowMessageToast({
        type: 'success',
        message: t('success'),
        description: t('qrCodeScreen.successMessages.checkoutSuccess'),
        backgroundColor: colors.successGreen,
        isWebView,
      });
      analyticsLogEvent({
        name: 'checkoutEvent',
        properties: {
          name: 'checkoutEvent',
          screen: 'Checkout Modal',
          purpose: 'Checkout a event',
        },
      });
      setModalVisible(false);
    } catch (error) {
      setLoading(false);
      networkErrorMessage(error);
      handleShowMessageToast({
        type: 'success',
        message: t('error'),
        description: t('qrCodeScreen.errorMessages.checkoutFail'),
        backgroundColor: colors.errorRed,
        isWebView,
      });
    }
    setLoading(false);
  };

  const openMapModal = async () => {
    setMapModalVisible(true);
  };

  return (
    <Modal
      style={styles.modal}
      animationIn="fadeIn"
      animationOut="fadeOut"
      isVisible={visible}
      coverScreen
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={onBackdropPress}
      onBackButtonPress={onBackButtonPress}
    >
      <View style={styles.container}>
        <Title
          title={!checkinAt ? 'Check-in' : 'Check-out'}
          hasCloseButton
          onPress={onBackdropPress}
        />
        <Image source={!checkinAt ? images.checkinGuy : images.checkoutGirl} style={styles.image} />
        <View style={styles.textGroup}>
          <Text fontSize={fontSizes.md2} style={styles.textLight} color={colors.darkGrey}>
            {!checkinAt
              ? t('reservationsScreen.checkinModal.checkinMajorText')
              : t('reservationsScreen.checkinModal.checkoutMajorText')}
          </Text>
          <Text
            fontSize={fontSizes.md2}
            fontType="bold"
            style={styles.text}
            color={colors.darkGrey}
          >
            {!checkinAt
              ? t('reservationsScreen.checkinModal.checkinMinorText')
              : t('reservationsScreen.checkinModal.checkoutMinorText')}
          </Text>
        </View>
        <View style={styles.buttonGroup}>
          {!checkinAt ? (
            <Button
              backgroundColor={colors.successGreen}
              textColor={colors.white}
              onPress={() => {
                setModalVisible(false);
                navigation.navigate(AppRoute.QR_CODE_SCANNER, { event });
              }}
            >
              {t('qrCodeScreen.readQr')}
            </Button>
          ) : (
            <Button
              loading={loading}
              backgroundColor={colors.successGreen}
              textColor={colors.white}
              onPress={handleCheckout}
            >
              {t('confirm')}
            </Button>
          )}
          {!checkinAt && (
            <Button
              loading={loading}
              onPress={openMapModal}
              textColor={colors.white}
              backgroundColor={colors.successGreen}
            >
              {t('qrCodeScreen.byLocation')}
            </Button>
          )}
          <MapModal
            visible={mapModalVisible}
            onBackButtonPress={() => setMapModalVisible(false)}
            onBackdropPress={() => setMapModalVisible(false)}
            setMapModalVisible={setMapModalVisible}
            setModalVisible={setModalVisible}
            event={event}
          />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    backgroundColor: colors.clear,
    borderRadius: 16,
    width: '85%',
    maxWidth: 550,
    paddingBottom: spacing.md,
    paddingHorizontal: spacing.xxxs,
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
  container: {
    justifyContent: 'center',
  },
  buttonGroup: {
    paddingHorizontal: spacing.md,
  },
  textGroup: {
    paddingHorizontal: spacing.md,
    alignSelf: 'center',
    marginBottom: 32,
  },
  textLight: {
    textAlign: 'center',
    marginBottom: 30,
  },
  text: {
    textAlign: 'center',
  },
  image: {
    width: 140,
    height: 140,
    alignSelf: 'center',
    marginTop: 30,
    marginBottom: spacing.md,
  },
});
