import { Feather } from '@expo/vector-icons';
import { useRoute, useNavigation } from '@react-navigation/native';
import { ArrowBackHeader, Button, DropdownButton, TimePicker, WebWrapper } from '_/components';
import CustomTextFieldModal from '_/components/ConfirmReserveModal/CustomTextFieldModal';
import { CustomModalType, FieldValuesState } from '_/components/ConfirmReserveModal/interface';
import CostCentersOptions from '_/components/CostCentersOptions';
import QuestionsField from '_/components/QuestionsField';
import { fieldsToCheck } from '_/components/QuestionsField/utils';
import { DATE_FORMAT, TIME_FORMAT } from '_/config/date';
import { colors, fontFamily } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useAuth } from '_/hooks/AuthContext';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { AppRoute } from '_/navigation/types';
import logger from '_/services/logger';
import { EventDate } from '_/services/models/date.model';
import { DropdownFieldOptionsRequest } from '_/services/models/dropdown-field-options.model';
import { ErrorCode } from '_/services/models/enums/error-code.enum';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { FieldsMetadata, SlotsModel } from '_/services/models/slots.model';
import handleShowMessageToast from '_/utils/handleShowMessageToast';
import { add, getHours, getMinutes, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView } from 'react-native';
import { Calendar } from 'react-native-calendars';
import FlashMessage, { showMessage } from 'react-native-flash-message';

import {
  calendarTheme,
  customDisabledDates,
  selectedCustomMark,
  todayCustomMark,
} from '../DatePicker/styles';
import { locale } from '../Meeting/WeekCalendar/addLocale';
import { IEditEventScreenParams } from './interfaces';
import styles from './styles';

const EditEvents: React.FC = () => {
  const { today, maxDate, checkOpenCloseHourMin } = useDateContext();
  const route = useRoute();
  const { event } = route.params as IEditEventScreenParams;
  const { slot } = event;
  const { space } = slot || {};
  const startAtToDateType = parseISO(event.startAt);
  const endAtToDateType = parseISO(event.endAt);
  const { disabledDateData } = useSpaceContext();
  const { updateEvent, getTodayEvents, modalMessage } = useEventContext();
  const navigation = useNavigation<any>();
  const { t } = useTranslation();
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [markedDates, setMarkedDates] = useState<Record<string, any>>({});
  const [selectedNewTime, setSelectedNewTime] = useState<EventDate>({
    start: startAtToDateType,
    end: endAtToDateType,
  });
  const { member, isWebView } = useAuth();
  const showCustomFields = Boolean(member?.showCustomFields);

  const [customModalVisible, setCustomModalVisible] = useState<CustomModalType>();

  const [fieldValues, setFieldValues] = useState<FieldValuesState>({
    dropdownField1: event.dropdownField1,
    dropdownField2: event.dropdownField2,
    textField1: event.textField1Value,
    textField2: event.textField2Value,
  });
  const [costCenter, setCostCenter] = useState<DropdownFieldOptionsRequest>({
    id: '',
    value: event.costCenter || '',
  });

  const {
    isIntervalInvalid,
    hasStartTimeBeforeCurrent,
    setSelectedDay,
    selectedEventDate,
    setSelectedEventDate,
    setUpdateEventDate,
  } = useDateContext();

  const selectedDay = useMemo(
    () => formatDate(selectedNewTime.start, DATE_FORMAT),
    [selectedNewTime]
  );

  const handleSetSelectedDay = (day: string) => {
    setSelectedDay(day);

    setSelectedNewTime((prevState) => ({
      start: add(parseISO(day), {
        hours: getHours(prevState.start),
        minutes: getMinutes(prevState.start),
      }),
      end: add(parseISO(day), {
        hours: getHours(prevState.end),
        minutes: getMinutes(prevState.end),
      }),
    }));
  };

  const handleEditEvent = async () => {
    const startAt = add(parseISO(selectedDay), {
      hours: selectedNewTime.start.getHours(),
      minutes: selectedNewTime.start.getMinutes(),
    });

    const endAt = add(parseISO(selectedDay), {
      hours: selectedNewTime.end.getHours(),
      minutes: selectedNewTime.end.getMinutes(),
    });

    if (checkOpenCloseHourMin(startAt, endAt, space?.openAtHourMin, space?.closeAtHourMin)) {
      showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
          openAt: space?.openAtHourMin ?? '00:00',
          closeAt: space?.closeAtHourMin ?? '23:59',
        }),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    const showError = fieldsToCheck({
      slot: slot as Partial<SlotsModel>,
      fieldValues,
      fieldsMetadata: slot?.fieldsMetadata as FieldsMetadata,
      showCustomFields,
    });

    if (showError) {
      return modalMessage.current.showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.quizRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    setRequestIsLoading(true);
    const newData = {
      ...(newDescription && { description: newDescription }),
      dropdownField1OptionId: fieldValues.dropdownField1?.id,
      dropdownField2OptionId: fieldValues.dropdownField2?.id,
      textField1Value: fieldValues.textField1,
      textField2Value: fieldValues.textField2,
      startAt,
      endAt,
      costCenter: costCenter.value,
    };

    try {
      await updateEvent(event.id, newData);
      handleShowMessageToast({
        message: t('success'),
        description: t('editEventScreen.successMessage'),
        backgroundColor: colors.successGreen,
        isWebView,
        type: 'success',
      });
      setRequestIsLoading(false);
      navigation.navigate(AppRoute.HOME);
      await getTodayEvents();
    } catch (error: any) {
      let errorMessage = t('workstationScreen.errorMessages.reservationFail');
      if (error?.response?.data?.data?.code === ErrorCode.HAS_CONFLICT) {
        errorMessage = t('workstationScreen.errorMessages.scheduleConflict');
      } else if (error?.response?.data?.data?.code === ErrorCode.HAS_USER_CONFLICT) {
        errorMessage = t('workstationScreen.errorMessages.alreadyScheduled');
      } else if (error?.response?.data?.data?.code === ErrorCode.NO_PAST_EVENT_SCHEDULE) {
        errorMessage = t('workstationScreen.errorMessages.noPastReservations');
      } else if (error?.response?.data?.data?.code === ErrorCode.HAS_MAX_CAPACITY_REACHED) {
        errorMessage = t('workstationScreen.noAvailableSlots.maxCapacityReached');
      } else if (error?.response?.data?.data?.code === ErrorCode.OPEN_CLOSE_SPACE_ERROR) {
        errorMessage = t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
          openAt: space?.openAtHourMin ?? '00:00',
          closeAt: space?.closeAtHourMin ?? '23:59',
        });
      } else if (error?.response?.data?.data?.code === ErrorCode.ONLY_ADMIN) {
        errorMessage = t('workstationScreen.errorMessages.onlyAdmin');
      } else {
        errorMessage = t('editEventScreen.errorMessage');
      }
      setRequestIsLoading(false);
      handleShowMessageToast({
        message: t('error'),
        description: errorMessage,
        backgroundColor: colors.errorRed,
        isWebView,
        type: 'danger',
      });
      logger(error);
    }
  };

  const selectedStartTime = useMemo(() => {
    return formatDate(selectedNewTime.start, TIME_FORMAT, { locale });
  }, [selectedNewTime]);

  const selectedEndTime = useMemo(() => {
    return formatDate(selectedNewTime.end, TIME_FORMAT, { locale });
  }, [selectedNewTime]);

  useEffect(() => {
    const eventDateStart = selectedEventDate.start.toString();
    const eventDateEnd = selectedEventDate.end.toString();
    const eventNewTimeStart = selectedNewTime.start.toString();
    const eventNewTimeEnd = selectedNewTime.end.toString();

    if (eventDateStart !== eventNewTimeStart || eventDateEnd !== eventNewTimeEnd) {
      setSelectedEventDate({
        start: selectedNewTime.start,
        end: selectedNewTime.end,
      });
    }
  }, [selectedNewTime]);

  const [newDescription, setNewDescription] = useState(event.description);

  useEffect(() => {
    const marks: Record<string, any> = {
      [formatDate(today, DATE_FORMAT)]: { selected: true, customStyles: todayCustomMark },
      [selectedDay]: {
        selected: true,
        customStyles: selectedCustomMark(space?.type),
      },
    };

    disabledDateData?.forEach(({ date }) => {
      marks[formatDate(parseISO(date), DATE_FORMAT)] = {
        selected: true,
        customStyles: customDisabledDates,
      };
    });

    setMarkedDates(marks);
  }, [disabledDateData, space?.type, selectedDay, today]);

  useEffect(() => {
    setUpdateEventDate({ start: new Date(event.startAt), end: new Date(event.endAt) });
    return () => {
      setUpdateEventDate(undefined);
    };
  }, []);

  return (
    <ScrollView style={{ flex: 1 }}>
      <WebWrapper>
        <FlashMessage
          floating
          position="bottom"
          duration={2000}
          titleStyle={{ fontFamily: fontFamily.bold }}
          textStyle={{ fontFamily: fontFamily.regular }}
          ref={modalMessage}
        />
        <View style={styles.wrapper}>
          <ArrowBackHeader style={{ marginBottom: 32 }}>
            {t('editEventScreen.editEvent')}
          </ArrowBackHeader>
          <DropdownButton
            text={newDescription || t('editEventScreen.newTitle')}
            icon="edit"
            tag={event.description && t('editEventScreen.newTitle')}
            onPress={() => setCustomModalVisible('title')}
          />
          <CostCentersOptions
            locationId={space?.location?.id as string}
            slotCostCenterEnabled={Boolean(slot?.costCenterEnabled)}
            memberShowCustomFields={Boolean(showCustomFields)}
            costCenter={costCenter}
            setCostCenter={setCostCenter}
            customModalVisible={customModalVisible}
            setCustomModalVisible={setCustomModalVisible}
            spaceType={space?.type as SpaceType}
          />
          <QuestionsField
            slot={event.slot as any}
            fieldValues={fieldValues}
            setFieldValues={setFieldValues}
            customModalVisible={customModalVisible}
            setCustomModalVisible={setCustomModalVisible}
            memberShowCustomFields={showCustomFields}
          />
          <Calendar
            theme={calendarTheme(space?.type)}
            minDate={today?.toISOString()}
            maxDate={maxDate?.toISOString()}
            onDayPress={(day) => handleSetSelectedDay(day.dateString)}
            markingType="custom"
            markedDates={markedDates}
            renderArrow={(direction) => (
              <Feather name={`chevron-${direction}` as any} size={28} color={colors.darkGrey} />
            )}
          />
        </View>
        <TimePicker
          editable
          defaultSelectedStartTime={selectedStartTime}
          defaultSelectedEndTime={selectedEndTime}
          setEventDate={setSelectedNewTime}
          selectedNewTime={selectedNewTime}
        />
        <View style={styles.buttonWrapper}>
          <View
            style={{
              opacity: !selectedDay || isIntervalInvalid || hasStartTimeBeforeCurrent ? 0.2 : 1,
            }}
          >
            <Button
              backgroundColor={getSpaceTypeColor(space?.type)}
              textColor={colors.white}
              onPress={handleEditEvent}
              disabled={isIntervalInvalid || hasStartTimeBeforeCurrent}
              loading={requestIsLoading}
            >
              {t('editEventScreen.editEvent')}
            </Button>
          </View>
        </View>
        <CustomTextFieldModal
          spaceType={space?.type as SpaceType}
          hideDropdown={() => setCustomModalVisible(undefined)}
          label={t('editEventScreen.newTitle')}
          setTextFieldValue={setNewDescription}
          value={newDescription}
          visible={customModalVisible === 'title'}
        />
      </WebWrapper>
    </ScrollView>
  );
};

export default EditEvents;
