import { Companies } from '_/constants/companies';
import Constants from 'expo-constants';

const company = Constants.expoConfig?.extra?.company as string;

export const images = {
  logo: require('./images/placehub/logo.png'),
  headerLogo: require('./images/placehub/headerLogo.png'),
  minimalLogo: require('./images/placehub/minimalLogo.png'),

  ...(company === Companies.DEZKER && {
    logo: require('./images/dezker/logo.png'),
    headerLogo: require('./images/dezker/headerLogo.png'),
    minimalLogo: require('./images/dezker/minimalLogo.png'),
  }),

  ...(company === Companies.INVENZI && {
    logo: require('./images/invenzi/logo.png'),
    headerLogo: require('./images/invenzi/headerLogo.png'),
    minimalLogo: require('./images/invenzi/minimalLogo.png'),
  }),

  googleLogo: require('./images/google.png'),
  microsoftLogo: require('./images/microsoftBtn.png'),
  spainFlag: require('./images/spain.png'),
  usFlag: require('./images/united-states.png'),
  brazilFlag: require('./images/brazil.png'),
  closedLocation: require('./images/closedLocation.png'),
  calendarYellow: require('./images/calendarYellow.png'),
  calendar: require('./images/calendar.png'),
  noContentGirl: require('./images/noContentGirl.png'),
  qrcode: require('./images/qrcode-icon.png'),
  denied: require('./images/denied.png'),
  checkoutGirl: require('./images/checkoutModalGirl.png'),
  checkinGuy: require('./images/checkinModalGuy.png'),
  user: require('./images/user.png'),
  upperCard: require('./images/upperCard.png'),
  lowerCard: require('./images/lowerCard.png'),
  notFound: require('./images/404ErrorAmico.png'),
};
