import { MessageType, showMessage } from 'react-native-flash-message';

const handleShowMessageToast = ({
  message,
  description,
  type,
  isWebView,
  backgroundColor,
}: {
  message: string;
  description: string;
  type?: MessageType;
  isWebView?: boolean;
  backgroundColor?: string;
}) => {
  if (isWebView) {
    return window.ReactNativeWebView?.postMessage(JSON.stringify({ message, description, type }));
  }
  showMessage({
    message,
    description,
    backgroundColor,
    type: !isWebView ? type : undefined,
  });
};

export default handleShowMessageToast;
